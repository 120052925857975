<template>
  <div>
    <vue-final-modal
      v-model="showModal"
      :esc-to-close="escToClose"
      classes="modal-container"
      content-class="modal-content"
      :style="cssVars"
    >
      <button
        class="modal__close"
        @click="showModal = false"
      >
        <i class="fa fa-close fa-stack-2x text-gray-500" />
      </button>
      <div
        class="modal__content overflow-y-auto"
        @click="showModal = true"
      >
        <slot />
      </div>
    </vue-final-modal>
    <div
      :class="classes"
      @click="showModal = true"
    >
      <div
        v-if="variant == 'popup' || variant == 'contact'"
      >
        <img
          v-if="variant == 'popup'"
          :src="url"
          class="image"
          style="width: 100%;height: 200px; object-fit: cover;"
        >
        <img
          v-else
          :src="url"
          class="image"
          style="width: 100%;"
        >
        <div
          v-if="variant == 'popup'"
          class="hover-container__middle"
        >
          <p class="uppercase text-gray-500 text-md font-bold">
            view {{ heading }} &gt;
          </p>
        </div>
        <p
          v-if="variant == 'contact'"
          class="pt-0 text-sm md:text-md cursor-pointer"
        >
          Contact Us
        </p>
      </div>

      <div
        v-if="variant == 'recipe'"
      >
        Share this recipe
      </div>
      <div
        v-if="variant == 'news'"
      >
        Share this news item
      </div>
    </div>
    <h3
      v-if="variant == 'popup'"
      class="text-lg leading-tight text-left text-green-400 hover:text-green-500 pt-1"
    >
      <a
        class="cursor-pointer"
        @click="showModal = true"
      >
        {{ heading }}
      </a>
    </h3>
  </div>
</template>

<script lang="ts">
  import {defineComponent} from 'vue';
  import { VueFinalModal, ModalsContainer } from 'vue-final-modal'

  export default defineComponent({
    components: {
      VueFinalModal,
      ModalsContainer /* eslint-disable-line vue/no-unused-components */
    },
    props: {
      variant: {
        type: String,
        default: "popup",
      },
      classes: {
        type: String,
        default: "",
      },
      url: {
        type: String,
        default: "",
      },
      heading: {
        type: String,
        default: "",
      },
      escToClose: {
        type: Boolean,
        default: true
      },
      height: {
        type: String,
        default: "90vh"
      },
      maxWidth: {
        type: String,
        default: "1200px"
      },
      maxHeight: {
        type: String,
        default: "800px"
      },
      bgColor: {
        type: String,
        default: "#fff"
      },
    },
    data: () => ({
      showModal: false
    }),
    computed: {
      // https://www.telerik.com/blogs/passing-variables-to-css-on-a-vue-component
      cssVars() {
        return {
          '--max-width': this.maxWidth,
          '--max-height': this.maxHeight,
          '--bg-color': this.bgColor,
        }
      }
    },
    mounted() {
      console.log('mounted VueTailwindModal');
    }
  });
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: var(--bg-color);
  width: 90vw;
  max-width: var(--max-width);
  max-height: var(--max-height);
}

::v-deep.modal__content h2 { /* stylelint-disable-line selector-class-pattern */
  padding: 10px 0 5px;
}

::v-deep.modal__content h3 { /* stylelint-disable-line selector-class-pattern */
  font-size: 26px;
  padding-bottom: 10px;
}

::v-deep.modal__content ul { /* stylelint-disable-line selector-class-pattern */
  padding-left: 20px;
  padding-right: 20px;
}

::v-deep.modal__content li { /* stylelint-disable-line selector-class-pattern */
  padding-bottom: 10px;
}

.modal_iframe { /* stylelint-disable-line selector-class-pattern */
  margin: auto;
  width: 100%;
}

.modal__close { /* stylelint-disable-line selector-class-pattern */
  position: absolute;
  top: 0.5rem;
  right: 2rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
