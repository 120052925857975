import { createApp } from 'vue';
import VueTailwindModal from '@/vue/VueTailwindModal.vue';

// App main
const main = async () => {
    // Create our vue instance
    const app = createApp({
      components: {
        VueTailwindModal
      }
    });

    // Mount the app
    const root = app.mount('.modal-container');

    return root;
};

// Execute async function
main().then( () => {
  console.log('loaded modal.ts');
});
